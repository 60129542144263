<template>
  <div class="add-royalties">
    <error :err.sync="error" />
    <advanced-data-table
      :search="false"
      :headers="headers"
      :items="royaltiesRaw"
      :total-items="royaltiesRaw.length"
      :loadingGlobal="loading"
      sticky-header="60vh"
      @row-clicked="rowClicked"
      ref="table"
      hide-actions
    >
      <template v-slot:cell(selected)="{ item, field: { key } }">
        <b-checkbox v-model="item[key]"></b-checkbox>
      </template>

      <template #cell(amount)="{item}">
        {{ formatCurrency(item.amount, item.sale_record.currency.code) }}
      </template>

      <template #cell(client)="{item}">
        {{ item.user ? item.user.name : "None" }}
      </template>

      <template #cell(sales_channel)="{item}">
        {{ item.sales_channel ? item.sales_channel.name : "None" }}
      </template>

      <template #cell(book)="{item}">
        <router-link
          target="_blank"
          :to="{
            name: 'AdminEditBook',
            params: { id: item.book.id }
          }"
        >
          {{ item.book.title }}
        </router-link>
      </template>

      <template #cell(date_of_sale)="{item}">
        {{ formatDate(item.sale_record.date_of_sale) }}
      </template>

      <template #cell(territory)="{item}">
        {{
          item.sale_record.territory ? item.sale_record.territory.code : "None"
        }}
      </template>

      <template #cell(status)="{item}">
        <!-- Use statusVariant from @/mixins/colors.js -->
        <h5 class="m-0">
          <b-badge :variant="statusVariant(item.status)" class="mr-1">{{
            $t(item.status)
          }}</b-badge>
        </h5>
      </template>

      <template #filters>
        <!-- Sales channel filter -->
        <adt-filter :label="$t('sales-channel')" :wide="true">
          <item-search
            action="salesChannels/search"
            label="name"
            trackBy="id"
            v-model="sales_channel"
          />
        </adt-filter>

        <!-- Territories filter -->
        <adt-filter :label="`Territories`" :wide="true">
          <tree-select
            :options="territories"
            trackBy="id"
            label="name"
            :multiple="true"
            v-model="selected_territories"
          />
        </adt-filter>

        <!-- Date range filter -->
        <adt-filter :label="$t('date-range')">
          <date-picker
            v-model="date_range"
            :placeholder="$t('date-range')"
            range
            valueType="format"
          />
        </adt-filter>

        <adt-filter>
          <ebp-button @click="get" :loading="loading" className="go-button"
            >{{ $t("go") }}
            <i class="bx bx-right-arrow-alt"></i>
          </ebp-button>
        </adt-filter>
      </template>

      <template #footer v-if="selected.length">
        <div class="floating-control">
          <div class="wrapper">
            <p>
              {{
                $t("total-temporary-value", {
                  total: formatCurrency(total, primaryCurrency)
                })
              }}
            </p>
            <p>
              Total Sales: {{ selected.length }} records.
              {{ selectedNumber }} net units sold.
            </p>
            <div class="input-cont">
              <b-input
                size="sm"
                type="number"
                v-model="actual_payment"
                :placeholder="$t('amount-received')"
              />
              <ebp-button
                :loading="adding"
                size="sm"
                className="ml-1 go-button"
                @click="add"
                variant="secondary"
              >
                {{ $t("go") }}
              </ebp-button>
            </div>
          </div>
        </div>
      </template>
    </advanced-data-table>
  </div>
</template>

<script>
import AdvancedDataTable from "@/components/advanced-data-table";
import AdtFilter from "@/components/core/adt-filter";
import ItemSearch from "@/components/core/item-search";
import DatePicker from "@/components/core/DatePicker";
import { sum } from "lodash-es";
import { mapState } from "vuex";
import TreeSelect from "../../../components/core/tree-select";

export default {
  name: "add-royalties",
  components: {
    ItemSearch,
    TreeSelect,
    AdtFilter,
    AdvancedDataTable,
    DatePicker
  },
  data() {
    return {
      headers: [
        "selected",
        "amount",
        "client",
        "book",
        "date_of_sale",
        "territory",
        "status"
      ],
      date_range: [],
      sales_channel: null,
      loading: false,
      adding: false,
      royaltiesRaw: [],
      royalties: [],
      error: null,
      actual_payment: null,
      listener: null,
      sales_channels: [],
      selected_territories: []
    };
  },
  mounted() {
    this.$store.dispatch("ancillaries/getSalesChannels");
    this.$store.dispatch("currencies/get");
    this.$store.dispatch("ancillaries/getTerritories");
    this.addScrollListener();
  },
  computed: {
    ...mapState("currencies", ["currencies"]),
    ...mapState("ancillaries", {
      salesChannels: "salesChannels"
    }),
    ...mapState("ancillaries", {
      territories: "territories"
    }),
    total() {
      return sum(this.selected.map(record => record.base_currency_amount));
    },
    primaryCurrency() {
      const currency = find(this.currencies, c => c.is_primary);
      return currency ? currency.code : "GBP";
    },
    selected() {
      return this.royaltiesRaw.filter(i => i.selected);
    },
    selectedNumber() {
      return this.sum(this.royaltiesRaw);
    }
  },
  methods: {
    sum(data) {
      return data.reduce((a, b) => a + b["sale_record"]["net_sales"] || 0, 0);
    },
    rowClicked(item) {
      if (item.selected) {
        this.$set(item, "selected", false);
      } else {
        this.$set(item, "selected", true);
      }
    },
    addScrollListener() {},
    async add() {
      this.adding = true;

      try {
        await this.$store.dispatch("royalties/addPayment", {
          royalty_ids: this.selected.map(r => r.id),
          payment_received: this.actual_payment,
          calculated_amount: this.total,
          date_from: this.date_range[0],
          date_to: this.date_range[1],
          sales_channel_id: this.sales_channel
        });
        this.get();
      } catch (err) {
        console.error(err);
      }

      this.adding = false;
    },
    async get() {
      this.error = null;
      const { date_range, sales_channel, selected_territories } = this;
      if (!date_range || !sales_channel) {
        this.error = this.$t("validation-errors.required-all");
      } else {
        this.loading = true;

        try {
          const res = await this.$store.dispatch("royalties/index", {
            sales_channel_id: sales_channel,
            date_range: Object.values(date_range).join(","),
            territories: Object.values(selected_territories).join(",")
          });

          const selected = res.data.map(i => ({
            ...i,
            selected: true
          }));

          this.royaltiesRaw = selected;
          this.royalties = selected.slice(0, 35);
        } catch (err) {
          console.error(err);
        }

        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.add-royalties {
  .checkbox {
    background: white;
    border: 2px solid $primary;
    border-radius: 5px;
    color: white;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    &.checked {
      background: $primary;
    }
  }

  .floating-control {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: -30px;
    z-index: 1000;

    p {
      margin: 0;
    }

    .wrapper {
      width: 65%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      background: $primary;
      color: white;
      padding: 1rem;
      box-sizing: border-box;
      border-radius: 10px;
      * > {
        flex: 1;
      }

      .input-cont {
        display: flex;
      }
    }
  }
}
</style>
